import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery } from "gatsby"
import { graphql } from "gatsby"

import "./reset.css"
import "./leveller.css"

import "@fontsource/josefin-sans/500.css"
import "@fontsource/josefin-sans/700.css"
import "@fontsource/roboto/100.css"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/raleway/300.css"
import "@fontsource/raleway/300-italic.css"
import "@fontsource/raleway/400.css"
import "@fontsource/raleway/400-italic.css"
import "@fontsource/raleway/900.css"


<link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500;700&family=Roboto:wght@100;300;400&family=Raleway:wght@300;300i;400;400i;900&display=swap" rel="stylesheet" /> 

const Head = ({ title }) => {

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <>
            <Helmet
                defaultTitle={data.site.siteMetadata.title}
                title={title}>
                <meta charset="utf-8" />
                <meta http-equiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
        </>     
    )
}

export default Head